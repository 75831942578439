<template>
  <div class="card h-100">
    <div class="card card-revenue-budget">
      <div class="row mx-0">
        <div class="revenue-report-wrapper col-md-8">
          <div class="d-sm-flex justify-content-between align-items-center mb-1">
            <h4 class="card-title mb-50 mb-sm-0">
              กำไรสุทธิวันนี้
              <span
                :class="[Number(data && data.todayDep && data.todayWit ? data.todayDep.reduce((total, item) => total +
                  item.amount, 0) -
                  data.todayWit.reduce((total, item) => total + item.amount, 0) : 0) > 0 ? 'text-success' : 'text-danger']">
                {{ data && data.todayDep && data.todayWit ? parseFloat(data.todayDep.reduce((total, item) => total +
                  item.amount, 0) -
                  data.todayWit.reduce((total, item) => total + item.amount, 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} ฿ <i
                  class="fas fa-chart-line" />
              </span>
            </h4>
          </div>
          <div>
            <div class="d-flex">
              <button class="mr-50 btn" :class="[activeChart === 'day' ? 'btn-success' : 'btn-outline-success']"
                @click="getProfitWeekly()">
                รายวัน
              </button>

              <button class="btn" :class="[activeChart === 'month' ? 'btn-success' : 'btn-outline-success']"
                @click="getProfitYearly()">
                รายเดือน
              </button>
            </div>

            <div class="mt-1">
              <vue-apex-charts ref="chart2" type="area" height="310" :options="chartOptions2" :series="series2" />

            </div>
          </div>
        </div>
        <div class="budget-wrapper col-md-4 d-flex align-items-center">
          <div class="w-100">
            <b-form-select v-model="selected" :options="options" class="w-75 rounded" />
            <p class="mb-0 mt-2">
              กำไรสุทธิ
            </p>
            <h2 class="my-25 text-info">
              {{ Commas(parseFloat(profit ? profit.profit : 0).toFixed(2)) }}฿
            </h2>
            <div class="d-flex justify-content-center text-success">
              <span class="font-weight-bolder mr-25">ยอดฝาก:</span><span>
                {{ Commas(parseFloat(profit ? profit.depositSum : 0).toFixed(2)) }}฿</span>
            </div>

            <div class="d-flex justify-content-center text-danger">
              <span class="font-weight-bolder mr-25">ยอดถอน:</span><span>-{{ Commas(parseFloat(profit ?
                  profit.withdrawSum : 0).toFixed(2)) }}฿</span>
            </div>

            <div class="d-flex justify-content-center text-info">
              <span class="font-weight-bolder mr-25">โบนัส:</span><span>{{ Commas(parseFloat(profit ? profit.bonusSum :
                  0).toFixed(2)) }}฿</span>
            </div>

            <div class="d-flex justify-content-center text-warning">
              <span class="font-weight-bolder mr-25">เกม:</span><span>{{ Commas(parseFloat(profit ? profit.gameSum :
                0).toFixed(2)) }} ฿</span>
            </div>
            <div class="resize-triggers">
              <div class="expand-trigger">
                <div style="width: 213px; height: 354px" />
              </div>
              <div class="contract-trigger" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  BRow, BCol, BFormSelect,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BFormSelect,
    VueApexCharts,
  },
  props: ['data'],
  // computed: {
  //   chartOptions2() {
  //     return {
  //       chart: {
  //         height: 350,
  //         type: 'area',
  //         toolbar: {
  //           show: true,
  //           offsetX: 0,
  //           offsetY: 310,
  //           position: 'bottom',
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         curve: 'smooth',
  //       },
  //       xaxis: {
  //         type: 'datetime',
  //         categories: this.$props.profitWeekly ? this.$props.profitWeekly.map(item => {
  //           return item.date;
  //         }) : [],
  //       },
  //       tooltip: {
  //         x: {
  //           format: 'dd/MM/yy HH:mm',
  //         },
  //       },
  //       legend: {
  //         position: 'top',
  //       },
  //     }
  //   }
  // },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'มกราคม' },
        { value: 2, text: 'กุมภาพันธ์' },
        { value: 3, text: 'มีนาคม' },
        { value: 4, text: 'เมษายน' },
        { value: 5, text: 'พฤษภาคม' },
        { value: 6, text: 'มิถุนายน' },
        { value: 7, text: 'กรกฎาคม' },
        { value: 8, text: 'สิงหาคม' },
        { value: 9, text: 'กันยายน' },
        { value: 10, text: 'ตุลาคม' },
        { value: 11, text: 'พฤศจิกายน' },
        { value: 12, text: 'ธันวาคม' },
      ],
      activeChart: 'day',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#00dd99',
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      series2: [
        // {
        //   name: 'ยอดฝาก',
        //   data: [31, 40, 28, 51, 42, 109, 100],
        // }, {
        //   name: 'ยอดถอน',
        //   data: [11, 32, 45, 32, 34, 52, 41],
        // }, {
        //   name: 'กำไรสุทธิ',
        //   data: [50, 79, 32, 21, 67, 90, 200],
        // }
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 310,
            position: 'bottom',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(0); // Format y-axis labels to 2 decimal places
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'top',
        },
      },
      profit: null,
      profitWeekly: [],
      profitYearly: []
    }
  },
  mounted() {
    this.getProfit();
    this.getProfitWeekly();
  },
  methods: {
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getProfitWeekly() {
      this.activeChart = 'day'
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/dashboard/getProfitWeekly');
        const initSeries = [
          {
            name: 'ยอดฝาก',
            data: [],
            color: "#009688"
          }, {
            name: 'ยอดถอน',
            data: [],
            color: "#EE6D7A"
          }, {
            name: 'กำไรสุทธิ',
            data: [],
            color: "#26d6eb"
          }
        ];

        this.profitWeekly = data;
        this.$refs.chart2.updateOptions({
          xaxis: {
            type: 'datetime',
            categories: data ? data.map(item => {
              return item.date;
            }) : [],
          },
        });
        data.forEach(item => {
          initSeries[0].data.push(item.depositSum);
          initSeries[1].data.push(item.withdrawSum);
          initSeries[2].data.push(item.profit);
        });
        this.$refs.chart2.updateSeries(initSeries);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getProfit() {
      this.activeChart = 'day'
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/dashboard/getProfit');

        this.profit = data

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getProfitYearly() {
      this.activeChart = 'month'
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/dashboard/getProfitYearly');

        this.profitYearly = data;
        const initSeries = [
          {
            name: 'ยอดฝาก',
            data: [],
          }, {
            name: 'ยอดถอน',
            data: [],
          }, {
            name: 'กำไรสุทธิ',
            data: [],
          }
        ];

        // this.profitWeekly = data;
        this.$refs.chart2.updateOptions({
          xaxis: {
            type: 'string',
            categories: data ? data.map(item => {
              return item.month + item.year;
            }) : [],
          },
        });
        data.forEach(item => {
          initSeries[0].data.push(item.depositSum);
          initSeries[1].data.push(item.withdrawSum);
          initSeries[2].data.push(item.profit);
        });
        this.$refs.chart2.updateSeries(initSeries);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
