<template>
  <div class="card h-100">
    <div class="p-2 h4 font-bolder text-danger">
      รายการฝาก
    </div>
    <b-table :items="data && data.depositLast ? data.depositLast : []" :fields="fields">
      <template #empty="scope">
        <div class="p-2 text-center">
          <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
      'ไม่พบข้อมูล' }}
        </div>
      </template>
      <template #cell(bank)="{ item }">
        <img :src="`/bankIcon/${item.path_photo}`" alt="" height="25" width="25" class="rounded-circle">
      </template>
      <!-- <template #cell(created_at)="{ item }">
        {{ item.created_at }}
      </template> -->
      <template #cell(amount)="{ item }">
        {{ item.amount.toLocaleString() }} ฿
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment-timezone';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    VueApexCharts,
    BCardText
  },
  props: ['data'],
  filters: {
    convertDate(val) {
      return moment(val).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss');
    }
  },
  data() {
    return {
      fields: [
        // { key: 'username', label: 'Username' },
        { key: 'fullname', label: 'ชื่อ' },
        { key: 'bank', label: 'ธนาคาร' },
        {
          key: 'created_at', label: 'ฝากเมื่อ', formatter: value => (value
            ? moment(value)
              .tz('Asia/Bangkok')
              .format('DD-MM-YYYY HH:mm')
            : '')
        },
        // { key: 'deposit_stamp', label: 'สั่งถอนเมื่อ' },
        { key: 'amount', label: 'จำนวนเงิน' },
      ],
      items: [
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'deposit',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'withdraw',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'deposit',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
      ],
      series: [44, 55, 13, 33],
      chartOptions: {
        chart: {
          width: 380,
          type: 'donut',
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        }],
        legend: {
          show: false,
          position: 'right',
          offsetY: 0,
          height: 230,
        },
      },
    }
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
