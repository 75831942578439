<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>

    <b-row>
      <b-col
        md="4"
        class="mb-1"
      >
        <Sec1Banks
          :agent="agent"
          :data="data"
        />
      </b-col>
      <b-col md="8">
        <Sec2Users
          :agent="agent"
          :data="data"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        md="4"
        class="mb-1"
      >
        <Sec3Sum
          :agent="agent"
          :data="data"
        />
      </b-col>
      <b-col md="8">
        <Sec4Profit
          :agent="agent"
          :data="data"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        md="8"
        class="mb-1"
      >
        <Sec5Withdraws
          :agent="agent"
          :data="data"
        />
      </b-col>
      <b-col md="4">
        <Sec6Depwitsum
          :agent="agent"
          :data="data"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        md="7"
        class="mb-1"
      >
        <Sec7Deposits
          :agent="agent"
          :data="data"
        />
      </b-col>
      <b-col md="5">
        <Sec8Zean
          :agent="agent"
          :data="data"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        md="8"
        class="mb-1"
      >
        <Sec9Dailysum
          :agent="agent"
          :data="data"
          :report-day="reportDay"
          :profit-days="profitDays"
        />
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <Sec10Monthlysum
          :agent="agent"
          :data="data"
          :report-year="reportYear"
        />
      </b-col>
    </b-row>

    <!-- <sec-one :data="data" :agent="agent" />
    <sec-two :data="data" />
    <sec-tree :data="data" />
    <sec-four />
    <data-table-view /> -->

  </b-overlay>
</template>

<script>
import {
  BIconController, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import DataTableView from './DataTable/view.vue'
// import SecOne from './DataTable/sec1.vue'
// import SecTwo from './DataTable/sec2.vue'
// import SecTree from './DataTable/sec3.vue'
// import SecFour from './DataTable/sec4.vue'
import Sec1Banks from './componenets/sec1_banks.vue'
import Sec2Users from './componenets/sec2_users.vue'
import Sec3Sum from './componenets/sec3_sum.vue'
import Sec4Profit from './componenets/sec4_profit.vue'
import Sec5Withdraws from './componenets/sec5_withdraws.vue'
import Sec6Depwitsum from './componenets/sec6_depwitsum.vue'
import Sec7Deposits from './componenets/sec7_deposits.vue'
import Sec8Zean from './componenets/sec8_zean.vue'
import Sec9Dailysum from './componenets/sec9_dailysum.vue'
import Sec10Monthlysum from './componenets/sec10_monthlysum.vue'

export default {
  components: {
    BIconController,
    BRow,
    BCol,
    BOverlay,
    DataTableView,
    Sec1Banks,
    Sec2Users,
    Sec3Sum,
    Sec4Profit,
    Sec5Withdraws,
    Sec6Depwitsum,
    Sec7Deposits,
    Sec8Zean,
    Sec9Dailysum,
    Sec10Monthlysum,
    // SecOne,
    // SecTwo,
    // SecTree,
    // SecFour,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      reportDay: [],
      reportYear: [],
      profitDays: '',
    }
  },
  computed: {
    agent() {
      return store.state.app.agent
    },
  },
  mounted() {
    this.getReportDay()
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/dashboard/index')

        // console.log(data)
        this.data = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getReportDay() {
      this.$http
        .get('dashboard/ReportDaysAndYears')
        .then(response => {
          this.reportDay = response.data.days
          this.profitDays = response.data.profitDay
          this.reportYear = response.data.years
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
.btn-success {
  box-shadow: rgba(0, 204, 136, 0.25) 0px 0px 10px 2px !important;
}

.btn-danger {
  box-shadow: rgba(230, 63, 89, 0.25) 0px 0px 10px 2px !important;
}
</style>
