<template>
  <div class="card h-100">
    <div class="p-2 h4 font-bolder">
      ยอดเสียทั้งหมดของยูสภายใต้พันธมิตร
    </div>
    <div class="mt-2 mx-auto">
      <vue-apex-charts type="bar" width="380" :options="chartOptions" :series="series" />
    </div>

    <div class="text-center my-3">
      <p class="mb-0">
        ยอดเสียทั้งหมด
      </p>

      <h2 class="font-weight-bolder">
        {{ data && data.winLossAll ? data.winLossAll.total_winloss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} ฿
      </h2>
    </div>

    <b-row class="text-center mx-0">
      <b-col cols="4" class="border-top border-right d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0">
          กีฬา
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data && data.winLossAll ? data.winLossAll.sport_winloss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} ฿
        </h3>
      </b-col>

      <b-col cols="4" class="border-top border-right d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0">
          สล็อต
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data && data.winLossAll ? data.winLossAll.slot_winloss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} ฿
        </h3>
      </b-col>

      <b-col cols="4" class="border-top d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0">
          คาสิโน

        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data && data.winLossAll ? data.winLossAll.casino_winloss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }} ฿
        </h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    VueApexCharts,
    BCardText
  },
  props: ['data'],
  computed: {
    series() {
      return [{
        name: 'ยอดเสีย',
        data: [
          this.$props.data ? this.$props.data.winLossAll.sport_winloss : 0,
          this.$props.data ? this.$props.data.winLossAll.slot_winloss : 0,
          this.$props.data ? this.$props.data.winLossAll.casino_winloss : 0,
        ],
      }]
    },
  },
  data() {
    return {
      fields: [
        { key: 'acc_name', label: 'ชื่อ' },
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'deposit_stamp', label: 'สั่งถอนเมื่อ' },
        { key: 'amount', label: 'ยอดคงเหลือ' },
      ],
      items: [
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'deposit',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'withdraw',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        {
          acc_name: 'john Doe',
          bank: { img: 123123123, acc_no: 11111111 },
          type: 'deposit',
          deposit_stamp: '11/11/1111',
          amount: 99.99,
        },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
        { acc_name: 'john Doe' },
      ],
      // series: [44, 55, 13, 33],
      chartOptions: {
        chart: {
          width: '100%',
          height: 500,
          type: 'bar',
        },
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        }, {
          name: 'Revenue',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        }, {
          name: 'Free Cash Flow',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        }],
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ฿`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#7367f0'],
          },
        },
        xaxis: {
          categories: ['กีฬา', 'สล็อต', 'คาสิโน'],
          position: 'bottom',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#7367f0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          reversed: true,
          labels: {
            show: true,
            formatter(val) {
              return `${val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ฿`
            },
          },
        },
      },
    }
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
